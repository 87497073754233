@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    height: 100vh;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
    z-index: 0;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
    z-index: 0;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
    z-index: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

 