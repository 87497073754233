 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
  }
  