@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html, body {
  position: relative;
  height: 100vh;
  /* Oculta el cursos de texto */
  caret-color: transparent;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
}

#root{
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding: 0px 0px 0px 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
}
