.tarjeta{
  height: 100vh;
}

div.transbox {
  position: absolute;
  top: 00px;
  left: 0%;
  transform: "translate(0%, 0%)";
  background-color: #ffffff;
  border: 0px;

  

  height: 100vh;
  width: 100%;
 
  opacity: 1;
  z-index: 99;
  
}


#uno{
  /* background-color:red; */
  /* background-color: #e2cdc7; */
}

#dos{
  /* background-color: blue; */
  /* background-color: #e2cdc7; */
}

#tres{
  /* background-color: green; */
  /* background-color: #e2cdc7; */
}
