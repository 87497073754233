code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    #textoSeccion4{
      position: absolute;
      top: 350%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
      /* height: 465px; */
      text-align: center;
    }
  
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion4{
      position: absolute;
      top: 351%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  
  /* #textoAsistencia{
    position: absolute;
    top: 33%;
    left: 49%;
    transform: translate(-50%, -50%);
    width: 465px;
    height: 465px;
    text-align: center;
  } */
  