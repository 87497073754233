 footer{
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-end;
    top: 0;
    bottom: 0;
    padding: 5px;
    font-size: 20px;
    background-color:rgba(0, 0, 0, 0.75);
    line-height: 1;
    text-align: center;
    width: 100%;
    z-index: 100000;
}

/* Estilo Botones */
.btn-label {
	position: relative;
	left: -12px;
	display: inline-block;
	padding: 6px 12px;
	background: rgba(0, 0, 0, 0.15);
	border-radius: 3px 0 0 3px;
}

.btn-labeled {
	padding-top: 0;
	padding-bottom: 0;
}

.btn {
	margin-bottom: 10px;
}