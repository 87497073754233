 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion9{
      position: absolute;
      top: 730%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion9{
      position: absolute;
      top: 750%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  