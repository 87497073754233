@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);

.text-center{
	text-align: center;
}

h1{
	font-size: 2.0em;
	margin: 0 0 0.5em 0;
	text-align: center;
	font-family: 'Abril Fatface', cursive;
}
h1 img{
	height: auto;
	margin: 0 auto;
	max-width: 240px;
	width: 100%;
}

.form-control{
	background: none;
	border: 1px solid #000000;
	line-height: 1em;
	font-weight: 300;
	padding: 0.125em 0.25em;
	width: 100%;
}
.form-control:focus{
	outline: none;
    background: none;
}
.form-control:-webkit-autofill{
	background-color: transparent !important;
    background: none;
}


.combos{
	background: none;
	border: 1px solid #000000;
	line-height: 1em;
	font-weight: 300;
	padding: 0.125em 0.25em;
}
.combos:focus{
	outline: none;
    background: none;
}
.combos:-webkit-autofill{
	background-color: transparent !important;
}