.tarjeta{
  height: 100vh;
}

#uno{
  /* background-color:red;  */
  /* background-color: #e2cdc7; */
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#dos{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#tres{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#cuatro{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#cinco{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#sies{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#siete{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#ocho{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#nueve{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#dies{
  background-image: url("./Seccion_1/1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

@media only screen and (max-width: 600px) {
  #uno{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  
  #dos{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  
  #tres{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #cuatro{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #cinco{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #sies{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #siete{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #ocho{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #nueve{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #dies{
    background-image: url("./Seccion_1/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
}

