@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

 