 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #textoSeccion2{
    position: absolute;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #textoSeccion2{
    position: absolute;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 1000%);
    z-Index: -1000
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #textoSeccion2{
    position: absolute;
    top: 157%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 1000%);
    z-Index: -1000
  }
}


