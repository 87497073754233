
.timer {
  background-color: rgba(2, 2, 2, 0);
  display: inline-block;
  margin-top: 5px;
  padding: 0px 10px 0 10px;
  text-align: center;
  width: 100%;
}

.col {
  width: 25%;
  float: left;
}

.box {
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  padding: 2px;
}

.col:last-child .box {
  border-right-color: transparent;
}

.box p {
  font-size: calc(12px + 2vmin);
  margin: 0;
  color: #62391B;
}

.text {
  font-size: 14px;
  color: #62391B;
}

.timerFinalizado p {
  font-size: calc(10px + 2vmin);
  margin: 0;
  color: #62391B;
}



