.Modal{

    width: 370px;    
	
	box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
    background-color:rgba(255,255,255,0.75);

	left: 0%;
	margin: 15px auto auto auto;
}

.ModalHeader{  

	background-color: rgba(255,255,255,0.75);
}

.ModalBody{   
	background-color: rgba(255,255,255,0.75);
}

.ModalFooter{   
	background-color: rgba(255,255,255,0.75);
}

#botonRegistro{
	left: 50%;
	transform: translate(0%, 0%);
	width: 20%;
} 
 
 /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px){
	.Modal{

		width: 360px;    
		
		box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
		background-color:rgba(255,255,255,0.75);
	
		left: 0%;
		margin: 15px auto auto auto;
	}
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 320px){
    .Modal{

		width: 360px;    
		
		box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
		background-color:rgba(255,255,255,0.75);
	
		left: 0%;
		margin: 15px auto auto auto;
	}
  }



