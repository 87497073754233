.recaptcha {
	margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.botones{
    margin-top: 10px;
}

.registar{
	background: rgba(93,193,185,0.75);
	border: 1px solid rgba(93,193,185,0.75);
	line-height: 1em;
	padding: 0.5em 0.5em;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
    color: black;
}

.noRegistrar{
	background: rgba(67, 67, 67, 0.75);
	border: 1px solid rgba(67, 67, 67, 0.75);
	line-height: 1em;
	padding: 0.5em 0.5em;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
    color: black;
}
