
@media only screen and (max-width: 600px) {

    .scroll-up {
        position: absolute;
        left: 50%;
        top: 9%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        -webkit-transform: translate(-50%, 0%) rotate(-45deg);
        -moz-transform: translate(-50%, 0%) rotate(-45deg);
        transform: translate(-50%, 0%) rotate(-45deg);
        -webkit-animation: fade_move_up 4s ease-in-out infinite;
        -moz-animation:    fade_move_up 4s ease-in-out infinite;
        animation:         fade_move_up 4s ease-in-out infinite;
      }
     
      .scroll-up2 {
        position: absolute;
        left: 50%;
        top: 11%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        -webkit-transform: translate(-50%, 0%) rotate(-45deg);
        -moz-transform: translate(-50%, 0%) rotate(-45deg);
        transform: translate(-50%, 0%) rotate(-45deg);
        -webkit-animation: fade_move_up 4s ease-in-out infinite;
        -moz-animation:    fade_move_up 4s ease-in-out infinite;
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      .scroll-up3 {
        position: absolute;
        left: 50%;
        top: 13%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        -webkit-transform: translate(-50%, 0%) rotate(-45deg);
        -moz-transform: translate(-50%, 0%) rotate(-45deg);
        transform: translate(-50%, 0%) rotate(-45deg);
        -webkit-animation: fade_move_up 4s ease-in-out infinite;
        -moz-animation:    fade_move_up 4s ease-in-out infinite;
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      /*animated scroll arrow animation*/
      @-webkit-keyframes fade_move_up {
        0%   { -webkit-transform:translate(0,10px) rotate(225deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { -webkit-transform:translate(0,-10px) rotate(225deg); opacity: 0; }
      }
      @-moz-keyframes fade_move_up {
        0%   { -moz-transform:translate(0,10px) rotate(225deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { -moz-transform:translate(0,-10px) rotate(225deg); opacity: 0; }
      }
      @keyframes fade_move_up {
        0%   { transform:translate(0,10px) rotate(225deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { transform:translate(0,-10px) rotate(225deg); opacity: 0; }
      }  
    
    
    
      .scroll-down {
        position: absolute;
        left: 50%;
        bottom: 52%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 10%;
        -webkit-transform: translate(-50%, 0%) rotate(45deg);
        -moz-transform: translate(-50%, 0%) rotate(45deg);
        transform: translate(-50%, 0%) rotate(45deg);
        -webkit-animation: fade_move_up 4s ease-in-out infinite;
        -moz-animation:    fade_move_up 4s ease-in-out infinite;
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      /*animated scroll arrow animation*/
      @-webkit-keyframes fade_move_down {
        0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
      }
      @-moz-keyframes fade_move_down {
        0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
      }
      @keyframes fade_move_down {
        0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
      }
    
    }