@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cookie|Raleway:300,700,400);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html, body {
  position: relative;
  height: 100vh;
  /* Oculta el cursos de texto */
  caret-color: transparent;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
}

#root{
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding: 0px 0px 0px 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
}

#App{
    position: relative;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    border: 0;
}










#contennedorUno{
    width: 100%;
}


#contennedorDos{
    width: 100%;
}
.tarjeta{
  height: 100vh;
}

#uno{
  /* background-color:red;  */
  /* background-color: #e2cdc7; */
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#dos{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#tres{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
}

#cuatro{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#cinco{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#sies{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#siete{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#ocho{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#nueve{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

#dies{
  background-image: url(../../static/media/1.556fe0ba.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 100%;
} 

@media only screen and (max-width: 600px) {
  #uno{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  
  #dos{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  
  #tres{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #cuatro{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #cinco{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #sies{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #siete{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #ocho{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #nueve{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  } 

  #dies{
    background-image: url(../../static/media/1.556fe0ba.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
}



@media only screen and (max-width: 600px) {

    .scroll-up {
        position: absolute;
        left: 50%;
        top: 9%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        transform: translate(-50%, 0%) rotate(-45deg);
        animation:         fade_move_up 4s ease-in-out infinite;
      }
     
      .scroll-up2 {
        position: absolute;
        left: 50%;
        top: 11%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        transform: translate(-50%, 0%) rotate(-45deg);
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      .scroll-up3 {
        position: absolute;
        left: 50%;
        top: 13%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 48%;
        transform: translate(-50%, 0%) rotate(-45deg);
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      /*animated scroll arrow animation*/
      @keyframes fade_move_up {
        0%   { transform:translate(0,10px) rotate(225deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { transform:translate(0,-10px) rotate(225deg); opacity: 0; }
      }  
    
    
    
      .scroll-down {
        position: absolute;
        left: 50%;
        bottom: 52%;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 23px;
        height: 23px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        z-index: 9;
        left: 10%;
        transform: translate(-50%, 0%) rotate(45deg);
        animation:         fade_move_up 4s ease-in-out infinite;
      }
    
      /*animated scroll arrow animation*/
      @keyframes fade_move_down {
        0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
        50%  { opacity: 1;  }
        100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
      }
    
    }
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #textoSeccion2{
    position: absolute;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #textoSeccion2{
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 800%);
    z-Index: -1000
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #textoSeccion2{
    position: absolute;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 1000%);
    z-Index: -1000
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #textoSeccion2{
    position: absolute;
    top: 157%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
  }

  #seccion2Box{
    top: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 1000%);
    z-Index: -1000
  }
}




.text-center{
	text-align: center;
}

h1{
	font-size: 2.0em;
	margin: 0 0 0.5em 0;
	text-align: center;
	font-family: 'Abril Fatface', cursive;
}
h1 img{
	height: auto;
	margin: 0 auto;
	max-width: 240px;
	width: 100%;
}

.form-control{
	background: none;
	border: 1px solid #000000;
	line-height: 1em;
	font-weight: 300;
	padding: 0.125em 0.25em;
	width: 100%;
}
.form-control:focus{
	outline: none;
    background: none;
}
.form-control:-webkit-autofill{
	background-color: transparent !important;
    background: none;
}


.combos{
	background: none;
	border: 1px solid #000000;
	line-height: 1em;
	font-weight: 300;
	padding: 0.125em 0.25em;
}
.combos:focus{
	outline: none;
    background: none;
}
.combos:-webkit-autofill{
	background-color: transparent !important;
}
.recaptcha {
	margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.botones{
    margin-top: 10px;
}

.registar{
	background: rgba(93,193,185,0.75);
	border: 1px solid rgba(93,193,185,0.75);
	line-height: 1em;
	padding: 0.5em 0.5em;
	transition: all 0.25s;
    color: black;
}

.noRegistrar{
	background: rgba(67, 67, 67, 0.75);
	border: 1px solid rgba(67, 67, 67, 0.75);
	line-height: 1em;
	padding: 0.5em 0.5em;
	transition: all 0.25s;
    color: black;
}


 /* #textoRegistroOk{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    text-align: center;
  } 


  @media only screen and (max-width: 600px) {

    #textoRegistroOk{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 430px;
      text-align: center;
    }

  }*/
.Modal{

    width: 370px;    
	
	box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
    background-color:rgba(255,255,255,0.75);

	left: 0%;
	margin: 15px auto auto auto;
}

.ModalHeader{  

	background-color: rgba(255,255,255,0.75);
}

.ModalBody{   
	background-color: rgba(255,255,255,0.75);
}

.ModalFooter{   
	background-color: rgba(255,255,255,0.75);
}

#botonRegistro{
	left: 50%;
	transform: translate(0%, 0%);
	width: 20%;
} 
 
 /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px){
	.Modal{

		width: 360px;    
		
		box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
		background-color:rgba(255,255,255,0.75);
	
		left: 0%;
		margin: 15px auto auto auto;
	}
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 320px){
    .Modal{

		width: 360px;    
		
		box-shadow: 0 0 1em 0 rgba(255,255,255,0.75);
		background-color:rgba(255,255,255,0.75);
	
		left: 0%;
		margin: 15px auto auto auto;
	}
  }




 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion3{
      position: absolute;
      top: 251%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion3{
      position: absolute;
      top: 250%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion3{
      position: absolute;
      top: 250%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion3{
      position: absolute;
      top: 250%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion3{
      position: absolute;
      top: 250%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion3{
      position: absolute;
      top: 260%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
  }
 
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    #textoSeccion4{
      position: absolute;
      top: 350%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
      /* height: 465px; */
      text-align: center;
    }
  
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion4{
      position: absolute;
      top: 351%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion4{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
    
    #ConfirmarAsistenciaBoton{
      margin-left: 1.5%;
    }
  }
  
  
  /* #textoAsistencia{
    position: absolute;
    top: 33%;
    left: 49%;
    transform: translate(-50%, -50%);
    width: 465px;
    height: 465px;
    text-align: center;
  } */
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    #textoSeccion5{
      position: absolute;
      top: 350%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion5{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion5{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion5{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion5{
      position: absolute;
      top: 351%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion5{
      position: absolute;
      top: 340%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      /* height: 465px; */
      text-align: center;
    }
  }
  

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion6{
      position: absolute;
      top: 457%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion6{
      position: absolute;
      top: 453%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion6{
      position: absolute;
      top: 435%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion6{
      position: absolute;
      top: 435%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion6{
      position: absolute;
      top: 460%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion6{
      position: absolute;
      top: 460%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }
  }
  
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion7{
      position: absolute;
      top: 560%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion7{
      position: absolute;
      top: 550%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion7{
      position: absolute;
      top: 550%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion7{
      position: absolute;
      top: 550%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion7{
      position: absolute;
      top: 560%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion7{
      position: absolute;
      top: 560%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion8{
      position: absolute;
      top: 650%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28%;
      /* height: 465px; */
      text-align: center;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion8{
      position: absolute;
      top: 660%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
    }
  }
  

.timer {
  background-color: rgba(2, 2, 2, 0);
  display: inline-block;
  margin-top: 5px;
  padding: 0px 10px 0 10px;
  text-align: center;
  width: 100%;
}

.col {
  width: 25%;
  float: left;
}

.box {
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  padding: 2px;
}

.col:last-child .box {
  border-right-color: transparent;
}

.box p {
  font-size: calc(12px + 2vmin);
  margin: 0;
  color: #62391B;
}

.text {
  font-size: 14px;
  color: #62391B;
}

.timerFinalizado p {
  font-size: calc(10px + 2vmin);
  margin: 0;
  color: #62391B;
}




 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #textoSeccion9{
      position: absolute;
      top: 733%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 83%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #textoSeccion9{
      position: absolute;
      top: 730%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 3000px) {
    #textoSeccion9{
      position: absolute;
      top: 750%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 27%;
      /* height: 465px; */
      text-align: center;
      z-index: 1000;
    }

    #contenedorTimer {
        position: relative;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
      }
    
    .timerFinalizado {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 10000;
        background-color: transparent;
        padding: 5px 0 30px 0;
        font-size: 25px;
    }
  }
  

 footer{
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-end;
    top: 0;
    bottom: 0;
    padding: 5px;
    font-size: 20px;
    background-color:rgba(0, 0, 0, 0.75);
    line-height: 1;
    text-align: center;
    width: 100%;
    z-index: 100000;
}

/* Estilo Botones */
.btn-label {
	position: relative;
	left: -12px;
	display: inline-block;
	padding: 6px 12px;
	background: rgba(0, 0, 0, 0.15);
	border-radius: 3px 0 0 3px;
}

.btn-labeled {
	padding-top: 0;
	padding-bottom: 0;
}

.btn {
	margin-bottom: 10px;
}
.tarjeta{
  height: 100vh;
}

div.transbox {
  position: absolute;
  top: 00px;
  left: 0%;
  transform: "translate(0%, 0%)";
  background-color: #ffffff;
  border: 0px;

  

  height: 100vh;
  width: 100%;
 
  opacity: 1;
  z-index: 99;
  
}


#uno{
  /* background-color:red; */
  /* background-color: #e2cdc7; */
}

#dos{
  /* background-color: blue; */
  /* background-color: #e2cdc7; */
}

#tres{
  /* background-color: green; */
  /* background-color: #e2cdc7; */
}

/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    height: 100vh;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
    z-index: 0;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
    z-index: 0;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
    z-index: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.2em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #LoginPersonalizado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    
    /* height: 465px; */
    text-align: center;
    z-index: 100;
    background-color: white;
  }

  .t1{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 25%;
  }
  
  .t2{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }

  .t3{
    font-size: 2.4em;
    font-family: 'Parisienne', cursive;
    margin-top: 2%;
  }
}

 
/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3000px) {
  #datosLogin{
    padding-top: 15px;
    margin: auto;
    width: 75%;
    background-color: transparent;
  }
}

 
